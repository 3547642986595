/*
 * @description 数组去重
 * @Author: guoguo 
 * @Date: 2018-05-12 17:04:35 
 * @Last Modified by: guoguo
 * @Last Modified time: 2019-08-26 11:11:34
 */
// 将对象元素转换成字符串以作比较
function obj2key(obj, keys) {
  var n = keys.length;
  var key = [];
  while (n--) {
    key.push(obj[keys[n]]);
  }
  return key.join("|");
}
/**
 * @description 数组内的对象去重，前面出现的覆盖后面出现的
 * @param {Array} array 去重的数组
 * @param {Array} keys 数组内对象的key
 */
function uniqeByKeys(array, keys) {
  var arr = [];
  var hash = {};
  for (var i = 0, j = array.length; i < j; i++) {
    var k = obj2key(array[i], keys);
    if (!(k in hash)) {
      hash[k] = true;
      arr.push(array[i]);
    }
  }
  return arr;
}
/**
 * @description 数组去重
 * @param {Array} arr 数组
 */
function unique(arr) {
  var res = [];
  var json = {};
  for (var i = 0; i < arr.length; i++) {
    if (!json[arr[i]]) {
      res.push(arr[i]);
      json[arr[i]] = 1;
    }
  }
  return res;
}

exports = module.exports = {
  uniqeByKeys,
  unique,
}