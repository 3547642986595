/*
 * @Desc: 获取字符串字节长度
 * @Author: guoguo
 * @Date: 2020-07-06 16:46:17
 * @Last Modified by: songyang
 * @Last Modified time: 2020-12-25 14:16:54
 */
// 字符编码数值对应的存储长度：
// UCS-2编码(16进制) UTF-8 字节流(二进制)
// 0000 - 007F       0xxxxxxx （1字节）
// 0080 - 07FF       110xxxxx 10xxxxxx （2字节）
// 0800 - FFFF       1110xxxx 10xxxxxx 10xxxxxx （3字节）
export default function getStrBytesLength(str) {
  let totalLength = 0;
  let charCode;
  for (let i = 0; i < str.length; i++) {
    charCode = str.charCodeAt(i);
    if (charCode < 0x007f) {
      totalLength++;
    } else if (charCode >= 0x0080 && charCode <= 0x07ff) {
      totalLength += 2;
    } else if (charCode >= 0x0800 && charCode <= 0xffff) {
      totalLength += 3;
    } else {
      totalLength += 4;
    }
  }
  return totalLength;
}
